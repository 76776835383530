// import photo1 from './img/photo/IMG_20170623_162248.jpg';
// import photo2 from './img/photo/16252551_1426057644071728_6311353642469637831_o.jpg';
// import photo3 from './img/photo/26114740_1768925176451638_8502002970424634553_o.jpg';
// import photo4 from './img/photo/20190823_181821247_iOS.jpg';
// import photo5 from './img/photo/72359553_912805989088615_5070294178093596672_n.jpg';
// import photo6 from './img/photo/48412378_2239204106090407_1830784768321519616_o.jpg';
// import photo7 from './img/photo/54435111_2364338446910305_6723065943703945216_o.jpg';
//
// import photo8 from './img/photo/IMG_3237.jpeg';
// import photo9 from './img/photo/IMG_3250.jpeg';
// import photo10 from './img/photo/IMG_3766.jpeg';
// import photo11 from './img/photo/IMG_4077.jpeg';
// import photo12 from './img/photo/IMG_4346.jpeg';
// import photo13 from './img/photo/IMG_4521.jpeg';


// 2022
// import middlePortrait from "./img/photo/IMG_7444.jpg";
// import bottomLeft from "./img/photo/sarka.jpeg";
// import photo15 from "./img/photo/IMG_9647.jpg";
// import topMain from "./img/photo/vaclavak.jpeg";
// import bottomRightPortrait from "./img/photo/monevago.jpeg";
// import topLeftPortrait from "./img/photo/bormio.jpeg";
// import topRightPortrait from "./img/photo/IMG_9663.jpg";

import IMG_1110 from "./img/photo/2024/IMG_1110.jpeg";
import IMG_1286 from "./img/photo/2024/IMG_1286.jpeg";
import IMG_2644 from "./img/photo/2024/IMG_2644.jpeg";
import IMG_3806 from "./img/photo/2024/IMG_3806.jpeg";
import IMG_4423 from "./img/photo/2024/IMG_4423.jpeg";
import IMG_4714 from "./img/photo/2024/IMG_4714.jpeg";
import IMG_5168 from "./img/photo/2024/IMG_5168.jpeg";
import IMG_1116 from "./img/photo/2024/IMG_1116.jpeg";
import IMG_1907 from "./img/photo/2024/IMG_1907.jpeg";
import IMG_2761 from "./img/photo/2024/IMG_2761.jpeg";
import IMG_4031 from "./img/photo/2024/IMG_4031.jpeg";
import IMG_4588 from "./img/photo/2024/IMG_4588.jpeg";
import IMG_4920 from "./img/photo/2024/IMG_4920.jpeg";
import IMG_5199 from "./img/photo/2024/IMG_5199.jpeg";
import IMG_1259 from "./img/photo/2024/IMG_1259.jpeg";
import IMG_1990 from "./img/photo/2024/IMG_1990.jpeg";
import IMG_3404 from "./img/photo/2024/IMG_3404.jpeg";
import IMG_4178 from "./img/photo/2024/IMG_4178.jpeg";
import IMG_4655 from "./img/photo/2024/IMG_4655.jpeg";
import IMG_4998 from "./img/photo/2024/IMG_4998.jpeg";
import IMG_5275 from "./img/photo/2024/IMG_5275.jpeg";

import IMG_6106 from "./img/photo/2025/IMG_6106.jpeg";
import IMG_6691 from "./img/photo/2025/IMG_6691.jpeg";
import IMG_6938 from "./img/photo/2025/IMG_6938.jpeg";
import IMG_8386 from "./img/photo/2025/IMG_8386.jpeg";
import IMG_8929 from "./img/photo/2025/IMG_8929.jpeg";
import IMG_9190 from "./img/photo/2025/IMG_9190.jpeg";
import IMG_9432 from "./img/photo/2025/IMG_9432.jpeg";
import IMG_9689 from "./img/photo/2025/IMG_9689.jpeg";

export const photos2025 = [
  IMG_6106,
  IMG_6691,
  IMG_6938,
  IMG_8386,
  IMG_8929,
  IMG_9190,
  IMG_9432,
  IMG_9689,
];

export const photos2024 = [
  IMG_1110,
  IMG_1286,
  IMG_2644,
  IMG_3806,
  IMG_4423,
  IMG_4714,
  IMG_5168,
  IMG_1116,
  IMG_1907,
  IMG_2761,
  IMG_4031,
  IMG_4588,
  IMG_4920,
  IMG_5199,
  IMG_1259,
  IMG_1990,
  IMG_3404,
  IMG_4178,
  IMG_4655,
  IMG_4998,
  IMG_5275
];

// export default [
//   IMG_4178, // middlePortrait,
//   IMG_1286, // photo15,
//   IMG_4920, // topMain,
//   IMG_4655, // bottomRightPortrait,
//   IMG_5275, // topLeftPortrait,
//   IMG_3806, // topRightPortrait,
//   IMG_4423, // bottomLeft
// ];


/*
export default [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7
];
*/
//
// export default [
//   photo12,
//   photo8,
//   photo13,
//   photo10,
//   photo9,
//   photo11,
//   photo4
// ];
